<template>
  <div class="about">
    <h1>Kite Project</h1>
    <h2></h2>
    <h2>licences</h2>
    <h2>Contribution</h2>
    <p>Some functionalities are provided by third parties services/API</p>
    <h3>Sunrise Sunset</h3>
    <p>
      Sunrise sunset calculator is provided by
      <a href="https://sunrise-sunset.org">sunrise-sunset.org</a>
    </p>
  </div>
</template>
